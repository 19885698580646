import { useEffect, useState } from "react";
import { H2hData } from "../types/h2hData";
import { HomepageData, HomepageEntry } from "../types/homepageData";
import { TournamentData } from "../types/tournamentData";

export function useFetchHomepage(prPeriod: string) {
  const [data, setData] = useState<HomepageData>();
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(`https://storage.googleapis.com/chicago_${convertPeriod(
        prPeriod
      )}/homepage.json`);

    if (!isValidPeriod(prPeriod)) {
      setError(new Error("invalid period"));
      setLoading(false);
      setData(undefined);
      return;
    }

    if (homepageCache.has(prPeriod)) {
      setData(homepageCache.get(prPeriod));
      setLoading(false);
      setError(undefined);
    }

    setLoading(true);
    fetch(
      `https://storage.googleapis.com/chicago_${convertPeriod(
        prPeriod
      )}/homepage.json`
    )
      .then((response) => response.json())
      .then((responseJSON: HomepageData) => {
        responseJSON.forEach((entry) => {
          if (
            entry.player.conservativeRating === 800 &&
            entry.player.prEvents < 3
          ) {
            entry.player.conservativeRating = undefined;
          }
        });
        setData(
          responseJSON.filter((entry) => !entry.player.name.includes("BYE"))
        );
        setError(undefined);
        homepageCache.set(prPeriod, responseJSON);
      })
      .catch((err) => {
        setData(undefined);
        console.log(err);
        setError(err);
      })
      .finally(() => setLoading(false));
  }, [prPeriod]);

  return { data, error, loading };
}

const homepageCache = new Map<string, HomepageData>();

export function useFetchHomepageEntry(player: string, prPeriod: string) {
  const [data, setData] = useState<HomepageEntry>();
  const [correctPeriod, setCorrectPeriod] = useState(true);
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isValidPeriod(prPeriod)) {
      setError(new Error("invalid period"));
      setLoading(false);
      setData(undefined);
      return;
    }

    let playerName = player.toLowerCase();
    if (player === "Q") playerName = "q?";

    const locatePlayer = () => {
      const homepageEntry = homepageCache
        .get(prPeriod)!
        .find((value) => value.player.name.toLowerCase() === playerName);

      if (homepageEntry) {
        setData(homepageEntry);
        setError(undefined);
        setCorrectPeriod(true);
        homepageEntryCache.set(playerName + prPeriod, homepageEntry);
      } else {
        let found = false;
        for (
          let i = parseInt(process.env.REACT_APP_DEFAULT_PERIOD!);
          i >= 2;
          i--
        ) {
          if (homepageCache.get(i.toString())) {
            const homepageEntry = homepageCache
              .get(i.toString())!
              .find((value) => value.player.name.toLowerCase() === playerName);
            if (homepageEntry) {
              setData(homepageEntry);
              setError(undefined);
              setCorrectPeriod(false);
              found = true;
              break;
            }
          }
        }
        if (!found) {
          setData(undefined);
          setError(new Error("Player not found"));
        }
      }
      setLoading(false);
    };

    if (homepageEntryCache.has(playerName + prPeriod)) {
      setData(homepageEntryCache.get(playerName + prPeriod));
      setCorrectPeriod(true);
      setError(undefined);
      setLoading(false);
      return;
    } else if (homepageCache.has(prPeriod)) {
      locatePlayer();
      return;
    }

    setLoading(true);
    fetch(
      `https://storage.googleapis.com/chicago_${convertPeriod(
        prPeriod
      )}/homepage.json`
    )
      .then((response) => response.json())
      .then((responseJSON: HomepageData) => {
        homepageCache.set(prPeriod, responseJSON);
        locatePlayer();
        setError(undefined);
      })
      .catch((err) => {
        setData(undefined);
        setError(err);
      })
      .finally(() => setLoading(false));
  }, [player, prPeriod, data]);

  return { data, error, loading, correctPeriod };
}

const homepageEntryCache = new Map<string, HomepageEntry>();

export function useFetchPlayerH2hs(player: string, prPeriod: string) {
  const [data, setData] = useState<H2hData>();
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isValidPeriod(prPeriod)) {
      setError(new Error("invalid period"));
      setLoading(false);
      setData(undefined);
      return;
    }

    let playerName = player.toLowerCase();

    if (playerName === "q") playerName = "q%3F";

    if (h2hCache.has(playerName + prPeriod)) {
      setData(h2hCache.get(playerName + prPeriod));
      setError(undefined);
      setLoading(false);
      return;
    }

    setLoading(true);
    fetch(
      `https://storage.googleapis.com/chicago_${convertPeriod(
        prPeriod
      )}/${playerName.replace("?", "%3F")}-h2hs.json`
    )
      .then((response) => response.json())
      .then((responseJSON: H2hData) => {
        setData(responseJSON);
        setError(undefined);
        h2hCache.set(playerName + prPeriod, responseJSON);
      })
      .catch((err) => {
        setData(undefined);
        setError(err);
      })
      .finally(() => setLoading(false));
  }, [player, prPeriod, data]);

  return { data, error, loading };
}

const h2hCache = new Map<string, H2hData>();

export function useFetchPlayerTournaments(player: string, prPeriod: string) {
  const [data, setData] = useState<TournamentData>();
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isValidPeriod(prPeriod)) {
      setError(new Error("invalid period"));
      setLoading(false);
      setData(undefined);
      return;
    }

    let playerName = player.toLowerCase();

    if (playerName === "q") playerName = "q%3F";

    if (tournamentCache.has(playerName + prPeriod)) {
      setData(tournamentCache.get(playerName + prPeriod));
      setError(undefined);
      setLoading(false);
      return;
    }

    setLoading(true);
    fetch(
      `https://storage.googleapis.com/chicago_${convertPeriod(
        prPeriod
      )}/${playerName.replace("?", "%3F")}-tournaments.json`
    )
      .then((response) => response.json())
      .then((responseJSON: TournamentData) => {
        setData(responseJSON);
        setError(undefined);
        tournamentCache.set(playerName + prPeriod, responseJSON);
      })
      .catch((err) => {
        setError(err);
        setData(undefined);
      })
      .finally(() => setLoading(false));
  }, [player, prPeriod, data]);

  return { data, error, loading };
}

export function useAutocompleteOptions(prPeriod: string) {
  const [data, setData] = useState<{ label: string }[]>();

  useEffect(() => {
    if (!isValidPeriod(prPeriod)) {
      setData(undefined);
      return;
    }

    if (homepageCache.has(prPeriod)) {
      setData(
        homepageCache.get(prPeriod)!.map((entry) => {
          return { label: entry.player.name };
        })
      );
    } else {
      fetch(
        `https://storage.googleapis.com/chicago_${convertPeriod(
          prPeriod
        )}/homepage.json`
      )
        .then((response) => response.json())
        .then((responseJSON: HomepageData) => {
          homepageCache.set(prPeriod, responseJSON);
          setData(
            responseJSON.map((entry) => {
              return { label: entry.player.name };
            })
          );
        })
        .catch((err) => {
          console.log(err);
          setData(undefined);
        });
    }
  }, [prPeriod]);

  return data ?? [];
}

const tournamentCache = new Map<string, TournamentData>();

function isValidPeriod(prPeriod: string) {
  const period = parseInt(prPeriod);

  if (isNaN(period)) {
    return false;
  }

  return (
    period === 0 ||
    (period >= 2 &&
      period <= parseInt(process.env.REACT_APP_DEFAULT_PERIOD! + 1))
  );
}

function convertPeriod(period: string) {
  const periodInt = parseInt(period);
  if (periodInt === 0) {
    return "all-time";
  } 
  else if (periodInt <= 4) {
    return `202${2 + Math.floor((periodInt - 1) / 4)}-${
      periodInt
    }`
  } else if (periodInt <= 7) {
    return `202${2 + Math.floor((periodInt - 1) / 4)}-${
      ((periodInt - 1) % 4) + 1
    }`;
  } else {
    return `202${2 + Math.floor((periodInt - 2) / 3)}-${
      ((periodInt - 2) % 3) + 1
    }`;
  }
}
