import { useSearchParams } from "react-router-dom";
import H2hMatrix from "../components/PR/H2hMatrix";
import { useEffect, useState } from "react";
import { useAutocompleteOptions, useFetchHomepage } from "../hooks/useFetch";
import { Autocomplete } from "@mui/material";

let PRESETS = {
  top5: ["Michael", "Skerzo", "Ober", "Killablue", "Unsure"],
  top10: [
    "Michael",
    "Skerzo",
    "Ober",
    "Killablue",
    "Unsure",
    "Hyunnies",
    "Azzu",
    "Ferocitii",
    "GI0GOAT",
    "Forest",
  ],
  next15: [
    "Eggy",
    "macdaddy69",
    "Scooby",
    "Frost",
    "Dragoid",
    "ORLY",
    "Olivia :3",
    "lovestory4a",
    "Kumi",
    "Certified",
    "Fasthands",
    "Umma",
    "Rocks",
    "Fry",
    "Phalanx",
  ],
};

function PRCandidateView() {
  const [queryParams] = useSearchParams();
  const [selectedPlayers, setSelectedPlayers] = useState<string[]>(
    PRESETS.top10
  );
  const period = queryParams.get("period");
  const options = useAutocompleteOptions(
    period ?? process.env.REACT_APP_DEFAULT_PERIOD!
  );
  const { data: players } = useFetchHomepage(
    period ?? process.env.REACT_APP_DEFAULT_PERIOD!
  );

  useEffect(() => {
    if (!!players) {
      const filteredPlayers = players.filter(
        (player) =>
          player.player.prEvents >=
            Number(process.env.REACT_APP_PR_EVENT_ELIGIBILITY) &&
          player.player.name !== "Mekk" &&
          player.player.name !== "NoFluxes"
      );
      PRESETS.top5 = filteredPlayers
        .slice(0, 5)
        .map((entry) => entry.player.name);
      PRESETS.top10 = filteredPlayers
        .slice(0, 10)
        .map((entry) => entry.player.name);
      PRESETS.next15 = filteredPlayers
        .slice(10, 25)
        .map((entry) => entry.player.name);
      setSelectedPlayers(
        filteredPlayers.slice(0, 10).map((entry) => entry.player.name)
      );
    }

    if (period === "10") {
      PRESETS = {
        top5: ["Ober", "Michael", "GI0GOAT", "Latin", "lovestory4a"],
        top10: [
          "Ober",
          "Michael",
          "GI0GOAT",
          "Latin",
          "lovestory4a",
          "ORLY",
          "Fry",
          "Unsure",
          "anxious",
          "Phalanx",
        ],
        next15: ["Jackie", "Ashley", "WattPheasant", "Binary Clone", "surskim"],
      };
      setSelectedPlayers([...PRESETS.top10, ...PRESETS.next15]);
    }
  }, [players, period]);

  const moveUp = (row: number) => {
    if (row !== 0 && row < selectedPlayers.length) {
      setSelectedPlayers([
        ...selectedPlayers.slice(0, row - 1),
        selectedPlayers[row],
        selectedPlayers[row - 1],
        ...selectedPlayers.slice(row + 1),
      ]);
    }
  };

  const moveDown = (row: number) => {
    if (row >= 0 && row < selectedPlayers.length - 1) {
      setSelectedPlayers([
        ...selectedPlayers.slice(0, row),
        selectedPlayers[row + 1],
        selectedPlayers[row],
        ...selectedPlayers.slice(row + 2),
      ]);
    }
  };

  const isMobile = window.innerWidth < 600;

  const onChange = (e: any, newValue: any) => {
    const onSubmit = (playerName: string) => {
      setSelectedPlayers([...selectedPlayers, playerName]);
    };

    if (!newValue) return;
    if (newValue.label) onSubmit(newValue.label);
    else onSubmit(newValue);
  };

  const removePlayer = (tag: string) => {
    setSelectedPlayers(selectedPlayers.filter((player) => player !== tag));
  };

  const surpriseMe = () => {
    const randomSet = new Set<string>();
    while (randomSet.size < 10) {
      const rand = Math.floor(Math.random() * 40);
      randomSet.add(options[rand].label);
    }
    setSelectedPlayers(Array.from(randomSet));
  };

  return (
    <div className="pr-page">
      <div className="pr-header">
        Create your own H2H Matrix for the Current PR Period. Type a tag in the
        box below to add a player or click on a player to remove.
        <br />
        <br />I recommend using this on desktop, especially if you want to
        compare a large number of players.
      </div>
      <div className="pr-button-row">
        <button
          className="pr-button"
          onClick={() => setSelectedPlayers(PRESETS.top5)}
        >
          Top 5
        </button>
        <button
          className="pr-button"
          onClick={() => setSelectedPlayers(PRESETS.top10)}
        >
          Top 10
        </button>
        <button
          className="pr-button"
          onClick={() =>
            setSelectedPlayers(PRESETS.top10.concat(PRESETS.next15))
          }
        >
          PR Candidates
        </button>
        {!isMobile && (
          <>
            <button className="pr-button" onClick={surpriseMe}>
              Surprise Me!
            </button>
          </>
        )}
        <button className="pr-button" onClick={() => setSelectedPlayers([])}>
          Reset
        </button>
      </div>
      <H2hMatrix
        playerNames={selectedPlayers}
        period={period ?? ""}
        removePlayer={removePlayer}
        moveUp={moveUp}
        moveDown={moveDown}
        mobile={isMobile}
      />
      <form action="#" className="matrix__autocomplete">
        <Autocomplete
          options={options.filter(
            (tag) => !selectedPlayers.includes(tag.label)
          )}
          onChange={onChange}
          freeSolo
          key={Math.random()}
          ListboxProps={{
            style: {
              maxHeight: "28rem",
              maxWidth: "100%",
            },
          }}
          renderInput={(params) => {
            return (
              <div className="matrix__search" ref={params.InputProps.ref}>
                <input
                  type="text"
                  {...params.inputProps}
                  className="matrix__input"
                  placeholder="New Player"
                />
              </div>
            );
          }}
        />
      </form>
    </div>
  );
}

export default PRCandidateView;
